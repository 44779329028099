<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/3 mb-6 md:pr-2">
        <img
          src="@/assets/RiskManagement/threat-min.jpg"
          width="307"
          height="439"
          alt=""
          title="threat"
          class="img-responsive wp-image-12089"
        />
      </div>
      <div class="w-full md:w-2/3 mb-6 md:pl-2">
        <TitleComponent title="Chemical Facility Security" size="large" />
        <Paragraph
          ><p>
            As part of the Homeland Security Appropriations Act of 2007 (section
            550), the Department of Homeland Security (DHS) was given authority
            to issue federal regulations to ensure the security of chemical
            facilities against terrorist and other criminal activities that
            could result in economic harm and loss-of-life. Subsequently the DHS
            has issued an interim final rule in 6 CFR Part 27. Those facilities
            that possess chemicals in the amounts specified in Appendix A of
            that regulation must submit a Top-Screen through the DHS’s Chemical
            Security Assessment Tool (CSAT). The Top-Screen is due 60 days after
            the final publication of Appendix A of 6 CFR 27 (final Appendix A
            expected by the end of June 2007) and registration in the CSAT
            system is required prior to submittal.
          </p>
          <p>
            The DHS will review the submitted Top-Screen and then determine
            whether the facility is a high-risk for potential terrorist attacks
            and as such assign it to a preliminary risk-based tier. That
            facility will then be required (as notified in writing from the DHS)
            to develop and submit a Security Vulnerability Assessment (SVA)
            detailing multiple security facets including asset characterization,
            threat assessment, security vulnerabilities, risk assessment and
            countermeasures analysis. Once the SVA has been approved by the DHS
            (again, notified in writing by the DHS), the facility will then be
            required to detail and submit a Site Security Plan (SSP). The site
            security plan should address the vulnerabilities identified in the
            SVA and satisfy the performance standards detailed in the federal
            regulation (6 CFR 27.230).
          </p>
          <p>
            Safety Management Services Inc. (SMS) is qualified to assist
            chemical facilities by providing support in understanding the
            federal regulation, assisting in drafting a site SVA and/or SSP that
            will satisfy the regulations specified by the Department of Homeland
            Security while ensuring that the chemical facility isn’t overly
            burdened by compliance. The process of characterizing security
            assets and threats and completing consequence and vulnerability
            analysis as part of the SVA and SSP is akin to the failure mode and
            effects analysis routine performed by SMS in assessing safety risks,
            characterizing them and identifying steps to mitigate the identified
            risks. SMS understands government regulations and their
            implementation and is recognized in the United States as an approved
            and authorized Examining Agency by the Department of Transportation
            to perform explosives and other hazardous materials examination
            services.
          </p>
          <p>
            SMS personnel have been mitigating processing risks and facilitating
            client’s compliance with federal regulations at explosive and
            chemical manufacturing facilities for more than 20 years across the
            United States and in multiple foreign countries. SMS can help you
            prepare for and submit a Top-Screen, SVA, and SSP as required.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Security Vulnerability Assessment",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS is qualified to assist in submitting a site Security Vulnerability Assessment (SVA) and developing a Site Security Plan (SSP) in accordance with Homeland Security Appropriations Act of 2007 (section 550) and DHS 6 CFR Part 27."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
